.main{
    background-color: lightcyan;
    color:slategray;
    height:87vh;
    /* padding:0px 15px 0px 15px; */
    text-align: center;
    max-width:100vw;
    margin:0;
}

.lecturerButton:hover, .dateDropdown:hover{
    background-color:hotpink;
}
.lecturerButton:focus, .dateDropdown:focus, .dropdownItem:hover{
    background-color: pink;
}
.lecturerButton:active, .dateDropdown:active, .dropdownItem:active{
    background-color:plum;
}

.lecturerButton, .dateDropdown{
    width:100%;
    height:10vh;
    background-color:pink;
    border-color:hotpink;
    text-align: center;
    /* font-size:40pt; */
}

.lecturerCol, .dropdownWrapper{
    padding-top:10px;
}

.textInput{
    width:95%;
    margin:10px;
    height: 10vh;
    font-size:30px;
    text-align:center;
}

.customDateInput{
    width:90%;
    margin:10px;
    height: 10vh;
    font-size:30px;
}

.lecturerRow::after{
    margin:10px 10% 0px 10%;
    width:90%;
    height:2.5px;
    background-color: hotpink;
    display: block;
    content:"";
}

.lecturerButton:disabled{
    background-color:lightblue;
}